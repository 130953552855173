import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from '@contentful/rich-text-types'
import styled from "@emotion/styled"

import Layoutwfooter from '../components/layoutwfooter'
import Content from '../components/content'
import Title from '../components/title'
import Seo from '../components/seo'

const Textblock = styled.div`
background-color: #fff;
padding: 18px 0 0 0;
span {
    font-size: .9125rem;
    letter-spacing: 2px;
    font-weight: 800;
}
h3 {
  padding-bottom: 1vh;
}
`
const Titling = styled.div`
padding-bottom: 2vh;
h3 {
    font-weight: 400;
    padding-top: .5vh;
    letter-spacing: .5px;
    font-size: .75rem;
    color: rgba(0,0,0,0.66);
}
`
const Sidebar = styled.div`
img {
  width: 18.5vw;
  height: 18.5vw;
  @media (max-width: 599px) {
    width: 43vw;
    height: 43vw;
    margin-top: 2.5vh;
  }
}
`
const options = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => (
    <Link to={`/project/${data.target.slug}`}>{children}</Link>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      return <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>{children}</a>
    },
  },
  renderText:
    text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
  },
}

const News = ({ data }) => {
    return (
      <>
      <Layoutwfooter>
        <Seo title={data.single.title}/>
          <Content>
              <Textblock>
              <Titling><Title>{data.single.title}</Title><h3>{data.single.date}</h3></Titling>
              {renderRichText(data.single.body, options)}
              </Textblock>
              <Sidebar>
                <GatsbyImage image={data.single.image.gatsbyImageData} alt="image" objectFit="cover" />
              </Sidebar>
          </Content>
      </Layoutwfooter>
      </>
  )
}

export const query = graphql`
query ($slug: String) {
  single: contentfulNews(slug: {eq: $slug}) {
    id
    title
    date(formatString:"MMM D, YYYY")
    image {
      gatsbyImageData
    }
    slug
    body {
      raw
    }
  }
}
`

export default News